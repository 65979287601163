import React from "react";
import styled from "styled-components";

import { Intro } from "./components/Intro";
import { Birthday } from "./components/Birthday";
import { Holidays } from "./components/Holidays";
import { Cocktail } from "./components/Cocktail";
import { Address } from "./components/Address";
import { Date } from "./components/Date";
import { CountdownTimer } from "./components/CountdownTimer";

export const HomeScreen = () => {
  return (
    <Container>
      <Intro />
      <Birthday />
      <Holidays />
      <Cocktail />
      <Address />
      <Date />
      <CountdownTimer />
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
`;
