import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";

export const CountdownTimer = () => {
  const end = moment("2024-08-30 20:00:00", "YYYY-MM-DD HH:mm:ss");
  const [now, setNow] = useState({now: moment()});
  const [duration, setDuration] = useState({duration: moment.duration(end.diff(now.now))});

  useEffect(() => {
    const interval = setInterval(() => {
      const update = moment();
      setNow(prevNow => ({...prevNow, now: update}));
      setDuration(prevDuration => ({...prevDuration, duration: moment.duration(end.diff(update))}));
    }, 60000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   setDuration(moment.duration(end.diff(now)));
  // }, [now, end]);

  //Get Days and subtract from duration
  let months = duration.duration.months();
  duration.duration.subtract(months, "months");

  //Get Days and subtract from duration
  let days = duration.duration.days();
  duration.duration.subtract(days, "days");

  //Get hours and subtract from duration
  let hours = duration.duration.hours();
  duration.duration.subtract(hours, "hours");

  //Get Minutes and subtract from duration
  let minutes = duration.duration.minutes();
  duration.duration.subtract(minutes, "minutes");

  return (
    <Container>
      <Wrapper>
        <Date>Mois</Date>
        <Remaining>{months}</Remaining>
      </Wrapper>
      <Wrapper>
        <Date>Jours</Date>
        <Remaining>{days}</Remaining>
      </Wrapper>
      <Wrapper>
        <Date>Heures</Date>
        <Remaining>{hours}</Remaining>
      </Wrapper>
      <Wrapper>
        <Date>Minutes</Date>
        <Remaining>{minutes}</Remaining>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16rem 0 13rem;
  background-image: url("./assets/img/background_bottom.png");
  background-size: cover;
  overflow: visible;

  @media only screen and (max-width: 48rem) {
    padding: 18rem 0 6rem;
    background-position: 65% 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 3.125rem;

  @media only screen and (max-width: 48rem) {
    margin: 0 .625rem;
  }
`;

const Date = styled.span`
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Poppins";
  font-style: italic;
  color: #FFD8AA;

  @media only screen and (max-width: 48rem) {
    font-size: .875rem;
  }
`;

const Remaining = styled.span`
  font-family: "Barrio";
  font-size: 12.5rem;
  line-height: .85;
  color: #FFFFFF;

  @media only screen and (max-width: 48rem) {
    margin-top: .5rem;
    font-size: 3.75rem;
  }
`;
