import React from "react";
import styled from "styled-components";

export const Cocktail = () => (
  <Container>
    <CocktailText>
      Retrouvons-nous pour un moment convivial autour d’un{" "}
      <TextBold>cocktail dînatoire</TextBold> et de stands culinaires
    </CocktailText>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;

  @media only screen and (max-width: 48rem) {
    margin-bottom: 8rem;
    padding: 0 2rem;
  }
`;

const CocktailText = styled.h3`
  align-self: center;
  font-size: 2.5rem;
  width: 60rem;
  text-align: center;
  font-weight: 300;
  margin: 0;

  @media only screen and (max-width: 48rem) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const TextBold = styled.span({
  fontFamily: "Poppins",
  fontStyle: "italic",
  fontWeight: 600
});
