import React, { useContext} from "react";
import styled from "styled-components"
import { AuthContext } from "../../../../App";

export const Intro = () => {
  let auth = useContext(AuthContext);
  const firstName = auth.user;
  return (
    <Container className="aaa_introduction">
      <div className="aaa_wrapper_title">
        <FirstName>{firstName}</FirstName>
        <SubTitle>
          Prépare-toi car en 2024 nous avons <span>trois bonnes raisons</span> de faire la fête !
        </SubTitle>
      </div>
      
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 12.5rem;
  padding-bottom: 20rem;
  background-image: url("./assets/img/background_top.png");
  background-size: cover;
  background-position-y: 99%;
  overflow: visible;
  z-index: -1;

  .aaa_wrapper_title {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    margin-left: 12%;
  }

  @media only screen and (max-width: 48rem) {
    padding-top: 7rem;
    background-position: 40% 0;

    .aaa_wrapper_title {
      margin-left: 0;
      padding: 0 2rem;
    }
  }
`;

const FirstName = styled.span`
  font-size: 4.375rem;
  font-family: "Barrio";
  color: #FFD8AA;
`;

const SubTitle = styled.h1`
  font-size: 2.5rem;
  font-family: "Poppins";
  font-weight: 300;
  line-height: 3rem;
  color: #FFFFFF;

  & span {
    font-family: "Poppins";
    font-weight: 600;
    font-style: italic;
  }

  @media only screen and (max-width: 48rem) {
    margin-left: 0;
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;