import React from "react";
import styled from "styled-components"

export const Input = ({name, placeholder}) => (<StyledInput name={name} placeholder={placeholder} />)

const StyledInput = styled.input`
  width: 15rem;
  height: 3.5rem;
  padding: .5rem 1rem;
  font-style: normal;
  color: #000;
  border-radius: .25rem;
  border: 0;
  box-shadow: 8px 4px 14px 0px #F958131A;
`;