import React from "react";
import styled from "styled-components"

export const Button = ({title, onClick, type, className, icon}) => (<StyledButton onClick={onClick} type={type} className={className}>{icon && icon}{title}</StyledButton>)

const StyledButton = styled.button`
  height: 3.5rem;
  padding: .875rem 1.5rem;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  background-color: #FF7D4A;
  border-radius: .25rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover, &:focus {
    background-color: #DA4100
  }
  `;