import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';

import { HomeScreen } from './pages/HomeScreen';
import { LoginScreen } from './pages/LoginScreen';

import GlobalStyles from "./globalStyles";

export const AuthContext = React.createContext(null);

function App() {
  
  function RequireAuth({ children }) {
    let auth = React.useContext(AuthContext);
    // let location = useLocation();
  
    if (!auth.user) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/" />;
    }
  
    return children;
  }

  function AuthProvider({ children }) {
    let [user, setUser] = React.useState(null);
  
    let signin = (newUser) => {
      return setUser(newUser);
    };
  
    let value = { user, signin };
  
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
  return (
    <ParallaxProvider>
      <AuthProvider>
        <GlobalStyles />
        <Routes>
          {/* Using path="*"" means "match anything", so this route
          acts like a catch-all for URLs that we don't have explicit
          routes for. */}
          <Route path="*" element={<LoginScreen />} />
          <Route path="/" element={<LoginScreen />}></Route>
          <Route path="/welcome" element={
              <RequireAuth>
                <HomeScreen />
              </RequireAuth>
            } />
        </Routes>
      </AuthProvider>
    </ParallaxProvider>
  );
}

export default App;
