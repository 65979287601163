import * as React from "react"
export const Email = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m7.575 15.217.022-4.47c.004-.912-.461-3.738.021-4.47 1.134-1.723 6.088-.748 7.892-.743 1.585.005 7.087-.833 8.01.838.472.853.035 3.385.045 4.384l.045 4.384c.01.99 1.549.992 1.539 0-.03-2.922-.027-5.847-.09-8.768-.028-1.29.111-1.985-1.209-2.338-1.222-.327-2.95-.022-4.22-.026l-4.708-.014c-2.536-.008-5.727-.547-8.18.09a.773.773 0 0 0-.538.536c-.85 3.095-.152 7.39-.167 10.597-.005.99 1.534.992 1.538 0Z"
    />
    <path
      fill="#fff"
      d="M23.975 9.466c2.52 2.465 4.202 3.846 4.358 7.6.096 2.297.128 4.597.133 6.896.002.937.48 3.844-.02 4.6-.744 1.129-2.176.758-3.324.762a105343.905 105343.905 0 0 1-15.926.051c-1.123.004-2.566.184-3.675.012-2.026-.314-1.996-.666-1.874-2.696a131.848 131.848 0 0 0 .195-11.326c-.063-2.451.093-3.557 2.347-5.012.83-.535.06-1.868-.776-1.329-1.082.699-2.507 1.57-3.029 2.814-.625 1.494-.067 3.742-.047 5.315.03 2.386-.006 4.771-.106 7.155-.077 1.822-.646 4.213-.197 5.996a.76.76 0 0 0 .538.537c2.057.45 4.505.08 6.624.072l8.575-.027 8.576-.028c.95-.003 2.703.429 3.404-.42.69-.838.244-3.157.249-4.176.012-2.683.004-5.366-.086-8.048-.055-1.65.194-4.005-.634-5.526-.915-1.678-2.877-3-4.218-4.31-.708-.693-1.797.394-1.087 1.087Z"
    />
    <path
      fill="#fff"
      d="M2.976 13.31a108.15 108.15 0 0 0 7.54 6.897c1.165.966 3.036 3.37 4.612 3.45 1.411.07 3.786-2.803 4.844-3.66l7.851-6.358c.77-.624-.325-1.706-1.088-1.088l-5.92 4.794c-1.398 1.133-3.945 4.183-5.657 4.207-1.488.022-3.994-2.782-5.179-3.801a106.507 106.507 0 0 1-5.915-5.528c-.7-.702-1.788.386-1.088 1.088Z"
    />
    <path
      fill="#fff"
      d="M18.265 21.11a49.26 49.26 0 0 0 4.246 4.413c1.44 1.313 3.639 2.52 4.701 4.15.54.826 1.872.056 1.329-.776-1.091-1.673-2.987-2.72-4.463-4.03a49.013 49.013 0 0 1-4.724-4.844c-.645-.754-1.729.338-1.088 1.087ZM11.482 20.627c-1.388 1.307-2.685 2.697-3.957 4.116-1.21 1.349-2.358 2.918-3.826 3.997-.79.58-.023 1.916.776 1.328 3.081-2.265 5.31-5.73 8.095-8.353.722-.68-.367-1.767-1.088-1.088ZM10.532 8.37h3.923c.99 0 .992-1.538 0-1.538h-3.923c-.99 0-.991 1.538 0 1.538ZM10.456 11.91h10.077c.99 0 .991-1.539 0-1.539H10.456c-.99 0-.992 1.538 0 1.538ZM10.456 15.755H20.84c.99 0 .992-1.538 0-1.538H10.456c-.99 0-.992 1.538 0 1.538ZM13.058 4.843a4.51 4.51 0 0 0 1.271-.919c.298-.303.735-1.155 1.117-1.305.701-.275 1.307.702 1.716 1.085a8.164 8.164 0 0 0 1.614 1.147c.865.484 1.642-.844.776-1.328-1.278-.714-2.346-2.705-3.963-2.58-1.487.115-2.048 1.959-3.307 2.572-.89.433-.111 1.76.776 1.328Z"
    />
  </svg>
)
