import React from "react";
import styled from "styled-components";
import { useParallax } from 'react-scroll-parallax';

export const Address = () => {
  const { ref } = useParallax({ translateX: [15, 0] });

  return (
  <Container>
    <img src="./assets/img/bike.png" alt="decoration" width="391" height="212" className="aaa_bike" />
    <Appointment ref={ref}>
      Rendez-vous Rendez-vous Rendez-vous
    </Appointment>
    <AddressText>Le Clos de la Source</AddressText>
    <SubAddressText>40 rue de Croix<br/>59510 Hem</SubAddressText>
  </Container>
)};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 26rem;
  overflow: hidden;

  .aaa_bike {
    position: absolute;
    top: 15rem;
    transform: translateY(-100%);
  }

  @media only screen and (max-width: 48rem) {
    overflow: visible;
    padding-top: 10rem;

    .aaa_bike {
      width: 177px;
      height: 96px;
      top: 5.25rem;
    }
  }
`;

const Appointment = styled.h4`
  position: absolute;
  top: 15rem;
  margin: 0;
  font-size: 10rem;
  font-family: "Barrio";
  color: #FF7D4A;
  font-weight: 400;
  white-space: nowrap;
  z-index: -1;

  @media only screen and (max-width: 48rem) {
    top: 5.125rem;
    font-size: 5.625rem;
  }
`;

const AddressText = styled.p`
  font-family: "Poppins";
  font-weight: bold;
  font-style: italic;
  font-size: 2.5rem;
  margin: 0;
  color: #FF9900;
  line-height: 3rem;

  @media only screen and (max-width: 48rem) {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;
  }
`;

const SubAddressText = styled.p`
  font-weight: 300;
  font-size: 2rem;
  margin: 0;
  text-align: center;
  line-height: 2.375rem;

  @media only screen and (max-width: 48rem) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: .125rem;
  }
`;
