import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { guests } from "./utils";
import { AuthContext } from "../../App";

export const useLoginScreen = () => {
  let navigate = useNavigate();
  const [isGuestInvite, setIsGuestInvite] = useState(true);

  let auth = useContext(AuthContext);

  const isGuest = (firstName, lastName) => {
    const user = {
      "firstName": firstName,
      "lastName": lastName
    }
    const userFirstName = user.firstName.trim();
    const userLastName = user.lastName.trim();

    const check = guests.map((guest) => {
      const guestFirstName = guest.firstName;
      const guestLastName = guest.lastName;
      const isFirstNameEqual = guestFirstName === userFirstName || guestFirstName.localeCompare(userFirstName, undefined, {sensitivity: "base"}) === 0;
      const isLastNameEqual = guestLastName === userLastName || guestLastName.localeCompare(userLastName, undefined, {sensitivity: "base"}) === 0;
      return isFirstNameEqual && isLastNameEqual;
    })
    return check.includes(true);
  }

  const handleAction = async (e) => {
    e.preventDefault();
    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    // Or you can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());
    const firstName = formJson.firstName;
    const lastName = formJson.lastName;
    const canContinue = isGuest(firstName, lastName);

    if (canContinue) {
      await auth.signin(`${firstName}`);
      navigate("welcome");
    } else {
      setIsGuestInvite(false)
    }
  }

  return {handleAction, isGuestInvite};
}