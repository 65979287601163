import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }

  body {
    font-size: 1rem;
    color: #000000;
    background-color: #FFFAF5;
  }

  h1, h2 {
    font-family: "Barrio", "Poppins";
    font-weight: 400;
    margin: 0;
  }

  input, input::placeholder {
    font-family: "Poppins", sans-serif;
    font-style: italic;
    font-size: 1rem;
    font-weight: 400;
    color: #828282;
  }
`

export default GlobalStyles;