import React from "react";
import styled from "styled-components";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button } from "../../../../components/Button";
import { Email } from "../../../../Icons/Email";
import { Message } from "../../../../Icons/Message";

export const Date = () => {
  const handleNotify = () => toast("Copié dans le presse-papier.");

  return (
  <Container>
    <img src="./assets/img/orange.png" alt="decoration" width="142" height="101" className="aaa_orange" />
    <Title>Vendredi 30 Août 2024 à partir de 20h</Title>
    <Text>
      Pour le principe,{" "}
      <TextBold>réponse souhaitée avant Juin 2024 :)</TextBold>
    </Text>
    <Buttons>
      <CopyToClipboard text="0614172433" onCopy={() => handleNotify()}>
        <Button title="06 14 17 24 33" type="submit" className="aaa_btn--l" icon={<IconWrapper><Message /></IconWrapper>} />
      </CopyToClipboard>
      <Button
        title="comelli.audrey@gmail.com"
        type="submit"
        className="aaa_btn--l"
        icon={<IconWrapper><Email /></IconWrapper>}
        onClick={(e) => {
          window.location.href = "mailto:comelli.audrey@gmail.com";
          e.preventDefault();
        }}
      />
    </Buttons>
    <ToastContainer autoClose={3000} />
  </Container>
)};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7.5rem;
  overflow: visible;

  .Toastify__progress-bar--wrp {
    --toastify-color-progress-light: linear-gradient( to right, #FFD8AA, #FF9900, #FF7D4A);
  }

  @media only screen and (max-width: 48rem) {
    padding: 0 2rem;

    .aaa_orange {
      width: 92px;
      height: 65px;
    }
  }
`;

const IconWrapper = styled.span`
  display: flex;
  margin-right: .75rem;

  @media only screen and (max-width: 48rem) {
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

const Buttons = styled.div`
  position: absolute;
  bottom: -4.625rem;
  transform: translateY(100%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .aaa_btn--l {
    display: flex;
    align-items: center;
    margin: 0 1rem;
    font-weight: 400;
    font-size: 1.25rem;
    text-transform: inherit;
    line-height: 1.5rem;
  }

  @media only screen and (max-width: 48rem) {
    flex-direction: column;
    bottom: -2.5rem;
    width: calc(100% - 4rem);

    .aaa_btn--l {
      justify-content: center;
      width: 100%;
      margin: .5rem 0;
      font-size: 1rem;
    }
  }
`;

const Title = styled.p`
  font-family: "Poppins";
  font-weight: bold;
  font-style: italic;
  font-size: 2.5rem;
  margin: 0;
  color: #FF9900;

  @media only screen and (max-width: 48rem) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: center;
    margin-top: .5rem;
    padding: 0 2rem;
  }
`;

const Text = styled.p`
  font-size: 1.25rem;
  text-align: center;
  font-weight: 300;
  margin: 0;

  @media only screen and (max-width: 48rem) {
    font-size: .875rem;
    text-align: center;
    margin-top: .25rem;
  }
`;

const TextBold = styled.span({
  fontFamily: "Poppins",
  fontStyle: "italic",
  fontWeight: 600
});