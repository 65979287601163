import React from "react";
import styled from "styled-components";
import { useParallax } from 'react-scroll-parallax';

export const Birthday = () => {
  const { ref } = useParallax({ translateY: [-10, 30] });

  return (
  <Container>
    <Ciao>Ciao</Ciao>
    <Cinquantaine><span>Cinquan</span>taine<img ref={ref} src="./assets/img/spritz.png" alt="spritz" width="698" height="763" className="aaa_spritz" /></Cinquantaine>
    <TwiceBirthday>Deux anniversaires...</TwiceBirthday>
    
  </Container>
)};

const Container = styled.div`
  padding-bottom: 7.5rem;
  margin-left: 5rem;

  @media only screen and (max-width: 48rem) {
    padding-top: 14rem;
    margin-left: 0;
  }
`;

const Ciao = styled.h2`
  font-family: "Poppins";
  font-weight: 600;
  font-style: italic;
  font-size: 2.5rem;
  color: #FF9900;
  margin-left: 11rem;
  line-height: 1;

  @media only screen and (max-width: 48rem) {
    font-size: 1.5rem;
    margin: 1rem 0 .25rem 3rem;
  }
`;

const Cinquantaine = styled.h2`
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-size: 10rem;
  line-height: 1;
  color: #FF7D4A;

  & span {
    font-family: "Barrio", "Poppins";
    font-weight: 400;
  }

  .aaa_spritz {
    position: absolute;
    bottom: -4rem;
    right: -27rem;
    z-index: -1;
  }

  @media only screen and (max-width: 48rem) {
    font-size: 5.25rem;
    text-align: center;
    line-height: .8;

    & span {
      display: block;
    }

    .aaa_spritz {
      right: 0;
      left: 0;
      bottom: 14rem;
      width: 398px;
      height: 463px;
    }
  }
`;

const TwiceBirthday = styled.p`
  font-family: "Poppins";
  font-weight: 300;
  font-size: 1.75rem;
  margin: 1rem 0 0 22rem;
  line-height: 1;

  @media only screen and (max-width: 48rem) {
    font-size: 1.25rem;
    margin: .25rem 0 0 7rem;
  }
`;
