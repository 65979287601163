import React from "react";

import styled from "styled-components"

import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { useLoginScreen } from "./LoginScreen.hooks";

export const LoginScreen = () => {
  const {
    handleAction,
    isGuestInvite
  } = useLoginScreen();

  return (
    <Container>
      <Form onSubmit={handleAction} className="aaa_form">
        {
          isGuestInvite ? <Title className="aaa_title">Hey <SubTitle>c'est une petite fête privée</SubTitle></Title> : <Title className="aaa_title aaa_oups">OUPS <SubTitle>il semblerait que tu ne sois pas sur la liste</SubTitle></Title>
        }
        <Label className="aaa_label">
          <Input name="firstName" placeholder="Prénom" />
        </Label>
        <Label className="aaa_label">
          <Input name="lastName" placeholder="Nom" />
        </Label>
        <Button title="Voir l'invitation" type="submit" />
      </Form>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .aaa_oups {
    margin-left: 0;

    span {
      margin-left: 1rem;
    }
  }

  @media only screen and (max-width: 48rem) {
    padding: 0 2rem;
    
    .aaa_title {
      flex-direction: column;
      font-size: 10rem;
      bottom: 10.125rem;
      margin: 0 0 1rem;
      line-height: .75;
      max-width: 320px;
      align-self: center;

      &.aaa_oups {
        font-size: 8rem;

        span {
          font-size: 1rem;
          padding-left: 4rem;
          text-wrap: wrap;
          text-align: left;
          line-height: 1.5;
        }
      }

      span {
        font-size: 1.25rem;
        margin: .25rem 0 0 0rem;
        padding-left: 3rem;
      }
    }

    .aaa_form {
      display: flex;
      flex-direction: column;
      flex: 1;

      input {
        width: 100%;
      }
    }

    .aaa_label {
      margin: 0 0 1rem 0;
    }
  }
`;

const Form = styled.form`
  transform: translateY(-25%);
  text-align: center;
`

const Title = styled.h1({
  // position: "absolute",
  // bottom: "2.5rem",
  // left: "2rem",
  display: "flex",
  flex: 1,
  marginLeft: "2rem",
  fontSize: "10rem",
  zIndex: -1,
  alignItems: "baseline",
  color: "#FF7D4A",
  lineHeight: .85
});

const SubTitle = styled.span({
  fontSize: "1.75rem",
  fontFamily: "Poppins",
  fontWeight: 300,
  textWrap: "nowrap",
  color: "#000000"
});

const Label = styled.label({
  marginRight: "1rem"
});
