import React from "react";
import styled from "styled-components";
import { useParallax } from 'react-scroll-parallax';

export const Holidays = () => {
  const { ref } = useParallax({ translateY: [-10, 30] });
  return (
    <Container>
      <Hello>Hello</Hello>
      <Holiday><img ref={ref} src="./assets/img/orange.png" alt="orange" width="384" height="273" className="aaa_orange" />Détente</Holiday>
      <Retiree>... et une retraite</Retiree>
    </Container>
  );
}



const Container = styled.div`
  padding-bottom: 17.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 48rem) {
    align-items: flex-end;
    padding-bottom: 10rem;

    .aaa_orange {
      top: 12rem;
      width: 25rem;
      height: 20.875rem;
      left: -4rem;
    }
  }
`;

const Hello = styled.h2`
  font-family: "Poppins";
  font-weight: 600;
  font-style: italic;
  font-size: 2.5rem;
  color: #FF9900;
  margin-right: 8rem;
  line-height: 1;

  @media only screen and (max-width: 48rem) {
    font-size: 1.5rem;
    margin: 0 11rem .25rem 0;
  }
`;

const Holiday = styled.h2`
  position: relative;
  font-weight: 400;
  font-size: 10rem;
  line-height: 1;
  color: #FF7D4A;

  .aaa_orange {
    position: absolute;
    left: -20rem;
    z-index: 1;
  }

  @media only screen and (max-width: 48rem) {
    font-size: 5.25rem;
    line-height: .8;

    .aaa_orange {
      width: 143px;
      height: 101px;
      left: -3rem;
      top: 2.25rem;
    }
  }
`;

const Retiree = styled.p`
  font-family: "Poppins";
  font-weight: 300;
  font-size: 1.75rem;
  margin: 1rem 0 0 10rem;
  line-height: 1;

  @media only screen and (max-width: 48rem) {
    font-size: 1.25rem;
    margin: .25rem 2rem 0 0;
  }
`;
