export const guests = [
  {
    firstName: "Lisa",
    lastName: "Comelli",
  },
  {
    firstName: "Arnaud",
    lastName: "Comelli",
  },
  {
    firstName: "Audrey",
    lastName: "Comelli",
  },
  {
    firstName: "Rémi",
    lastName: "Comelli",
  },
  {
    firstName: "Benjamin",
    lastName: "Ngoum",
  },
  {
    firstName: "Thibaud",
    lastName: "Comelli",
  },
  {
    firstName: "Madison",
    lastName: "Kuras",
  },
  {
    firstName: "Sylvie",
    lastName: "Fortin",
  },
  {
    firstName: "Jos",
    lastName: "Fernandez",
  },
  {
    firstName: "Manuel",
    lastName: "Tetu",
  },
  {
    firstName: "Jimmy",
    lastName: "Tetu",
  },
  {
    firstName: "Anne",
    lastName: "Grumiaux",
  },
  {
    firstName: "Bob",
    lastName: "Grumiaux",
  },
  {
    firstName: "Robert",
    lastName: "Grumiaux",
  },
  {
    firstName: "Bertrand",
    lastName: "Grumiaux",
  },
  {
    firstName: "Betty",
    lastName: "Grumiaux",
  },
  {
    firstName: "Antonin",
    lastName: "Grumiaux",
  },
  {
    firstName: "Fantine",
    lastName: "Grumiaux",
  },
  {
    firstName: "Fanny",
    lastName: "Barbery",
  },
  {
    firstName: "Thibaut",
    lastName: "Barbery",
  },
  {
    firstName: "Ugo",
    lastName: "Barbery",
  },
  {
    firstName: "Nina",
    lastName: "Barbery",
  },
  {
    firstName: "Louna",
    lastName: "Barbery",
  },
  {
    firstName: "Patrice",
    lastName: "Comelli",
  },
  {
    firstName: "Marie-Hélène",
    lastName: "Comelli",
  },
  {
    firstName: "Marie Hélène",
    lastName: "Comelli",
  },
  {
    firstName: "Françoise",
    lastName: "Steenhaut",
  },
  {
    firstName: "Franck",
    lastName: "Comelli",
  },
  {
    firstName: "Marie",
    lastName: "Vanhelle",
  },
  {
    firstName: "Lilou",
    lastName: "Comelli",
  },
  {
    firstName: "Florent",
    lastName: "Comelli",
  },
  {
    firstName: "Aline",
    lastName: "Dhonte",
  },
  {
    firstName: "Thomas",
    lastName: "Comelli",
  },
  {
    firstName: "Edith",
    lastName: "Homble",
  },
  {
    firstName: "Michel",
    lastName: "Homble",
  },
  {
    firstName: "Elise",
    lastName: "Boudjema",
  },
  {
    firstName: "Antony",
    lastName: "Boudjema",
  },
  {
    firstName: "Clara",
    lastName: "Boudjema",
  },
  {
    firstName: "Sam",
    lastName: "Boudjema",
  },
  {
    firstName: "Bruno",
    lastName: "Comelli",
  },
  {
    firstName: "Fabienne",
    lastName: "Comelli",
  },
  {
    firstName: "Audrey",
    lastName: "Dehaynin",
  },
  {
    firstName: "Cédric",
    lastName: "Dehaynin",
  },
  {
    firstName: "Ninon",
    lastName: "Dehaynin",
  },
  {
    firstName: "Téo",
    lastName: "Dehaynin",
  },
  {
    firstName: "Hélène",
    lastName: "Comelli",
  },
  {
    firstName: "Antoine",
    lastName: "Anzolin",
  },
  {
    firstName: "Nathalie",
    lastName: "Gornard",
  },
  {
    firstName: "Alain",
    lastName: "Sergheraert",
  },
  {
    firstName: "Marie-claire",
    lastName: "Houliez",
  },
  {
    firstName: "Marie claire",
    lastName: "Houliez",
  },
  {
    firstName: "Stéphane",
    lastName: "Jedrusiak",
  },
  {
    firstName: "Michel",
    lastName: "Lesueur",
  },
  {
    firstName: "Alain",
    lastName: "DEBERGUE",
  },
  {
    firstName: "Odile",
    lastName: "DEBERGUE",
  },
  {
    firstName: "Michèle",
    lastName: "LEPERCQ",
  },
  {
    firstName: "Dominique",
    lastName: "LEPERCQ",
  },
  {
    firstName: "Rachel",
    lastName: "Lariviere",
  },
  {
    firstName: "Gilles",
    lastName: "Dupont",
  },
  {
    firstName: "Christine",
    lastName: "Laroye dupont",
  },
  {
    firstName: "Bernard",
    lastName: "DILLY",
  },
  {
    firstName: "Roselyne",
    lastName: "DILLY",
  },
  {
    firstName: "Didier",
    lastName: "PROOT",
  },
  {
    firstName: "Corinne",
    lastName: "PROOT",
  },
  {
    firstName: "Christine",
    lastName: "ROBILLIART",
  },
  {
    firstName: "Benoit",
    lastName: "DEPOORTERE",
  },
  {
    firstName: "Marie",
    lastName: "DEPORTER",
  },
  {
    firstName: "Françoise",
    lastName: "WARIN",
  },
  {
    firstName: "Marco",
    lastName: "Scacchia",
  },
];
